.navbarheading,
.navbarheading:hover,
.navbarheading:focus {
    color: #666666;
    font-size: 17px;
    font-weight: 500;
    padding: 0.5rem 1rem !important;
    position: relative;
}

.logoimage {
    height: 150px;
    width: 150px;
}

.navbarbrand {
    border-bottom: 1px solid #D2D2D2
}

.navbarheading button {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
}

.switchmode input:checked {
    background-color: #eea3341e;
    border: 0;
}

.lightmode {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
}


.active button,
.active button:hover,
.active button:focus,
.active button:active {
    color: #000 !important;
}

.activesubmenu {
    color: #FFFFFF !important;
    background-color: #EEA534 !important;
    border-radius: 6px;
}

.accordionbody {
    margin: 0.5rem;
    background-color: #eea3341e;
    border-radius: 10px;
    padding: 8px !important;
}

.activemenu {
    background-color: #EEA534;
    border-radius: 6px;
}

.activemenu {
    color: #FFFFFF !important;
}

.submenu,
.submenu:hover,
.submenu:focus {
    font-size: 12px;
    background-color: transparent;
    color: #000;
    width: auto;
    padding: 0.5rem 1rem;
    font-weight: 500;
}

.username {
    position: absolute;
    bottom: 2%;
    left: 0;
    width: 100%;
    text-decoration: none;
}

.open,
.open button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.navbarheading button:not(.collapsed) {
    background-color: unset !important;
    font-weight: 500;
}

.heading {
    font-size: 22px;
    color: #000;
    margin-bottom: 0 !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #EEA534;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

@media only screen and (max-width: 1440px) {

    .navbarheading,
    .navbarheading:hover,
    .navbarheading:focus {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
    }
}

@media only screen and (max-width: 1024px) {
    .navbarbrand {
        border-bottom: 0;
    }
}

@media only screen and (max-width: 768px) {

    .navbarheading,
    .navbarheading:hover,
    .navbarheading:focus,
    .navbarheading button {
        font-size: 16px;
    }

    .navbarbrand svg {
        height: 65px;
        width: 200px;
    }
}