.heading {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0 !important;
}

.header {
  border-bottom: 1px solid #D2D2D2;
  width: 100%;
}

.top {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.content {
  font-size: 12px;
}

.subcontent {
  font-size: 10px;
  opacity: 50%;
}

.userimage {
  height: 30px;
  width: 30px;
  border-radius: 10px;
}

@media only screen and (max-width: 1200px) {
  /* .top {
    padding-bottom: 10px;
  } */

  .userdetail {
    bottom: 16px;
  }

  .header {
    display: flex;
  }
}

@media only screen and (max-width: 976px) {
  .heading {
    font-size: 18px;
    /* width: 200px; */
  }
}

@media only screen and (max-width: 768px) {

  .top {
    padding-left: 8px;
    padding-right: 8px;
  }

  .userdetail {
    bottom: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .heading {
    /* width: 180px; */
    font-size: 16px;
  }

  .userimage {
    height: 25px;
    width: 25px;
  }

  .top {
    padding-left: 2px;
    padding-right: 2px;
  }
}