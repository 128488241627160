/* providerdata.module.css */

.container {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .licenseContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .unverifiedSection,
  .verifiedSection {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .licenseItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .licenseItem span {
    font-size: 14px;
    color: #333;
  }
  
  .verifyButton,
  .unverifyButton {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verifyButton {
    background-color: #28a745; /* Green */
    transition: background-color 0.2s;
  }
  
  .verifyButton:hover {
    background-color: #218838;
  }
  
  .unverifyButton {
    background-color: #dc3545; /* Red */
    transition: background-color 0.2s;
  }
  
  .unverifyButton:hover {
    background-color: #c82333;
  }
  
  .saveButton {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff; /* Blue */
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .saveButton:hover {
    background-color: #0056b3;
  }

  .submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #EEA534;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}
  
  @media (max-width: 768px) {
    .licenseContainer {
      flex-direction: column;
    }
  
    .unverifiedSection,
    .verifiedSection {
      margin-bottom: 20px;
    }
  }
  