.button,
.button:hover,
.button:focus,
.button:active {
    background-color: #EEA534;
    color: #FFFFFF;
    border-radius: 30px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
}
.editbutton,
.editbutton:hover,
.editbutton:focus,
.editbutton:active {
    background-color: #EEA534;
    color: #FFFFFF;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px;
    display: flex;
    align-items: center;
}