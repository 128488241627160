.section1 {
    padding: 10px;
    background-color: #EEA534;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rowheading {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.inputfield {
    width: 80px;
    border-radius: 80px;
    font-size: 14px;
}

.inputfieldsearch {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none !important;
    font-size: 14px;
}

.inputfieldsearch:focus {
    border: none !important;
}

.searchbar {
    width: 350px;
    border: 1px solid #DDDCDC;
    border-radius: 30px;
}

.searchButton {
    background-color: #FFFFFF !important;
    border: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 12px;
}

.searchButton svg path {
    fill: #545454;
}

.button,
.button:hover,
.button:focus,
.button:active {
    background-color: #EEA534;
    color: #FFFFFF;
    border-radius: 30px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
}

.tableContainer tr {
    border-bottom: 1px solid #CACACA !important;
}

.tablesection th,
.tablesection td {
    box-shadow: none;
}

.userprofile {
    height: 32px;
    width: 32px;
    border-radius: 50px;
    color: #EED378;
}

.content {
    font-size: 24px;
    margin-bottom: 0 !important;
}

.tabledata {
    font-size: 14px;
    font-weight: 500;
    background-color: #FFFFFF;
}

.tabledata td {
    vertical-align: middle;
}

.tableContainer div {
    border-radius: 12px;
}

.tableContainer {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #EEA534;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.mainmodal {
    max-width: 900px !important;
}

@media only screen and (max-width: 1440px) {
    .tabledata {
        font-size: 16px;
    }

    .button svg {
        height: 25px;
        width: 25px;
    }

    .tableContainer table {
        min-width: 1500px !important;
    }
}

@media only screen and (max-width: 425px) {
    .rowheading {
        font-size: 14px;
    }

    .tabledata td {
        padding: 0.5rem !important;
    }

    .tabledata {
        font-size: 12px;
    }

    .tableContainer table {
        min-width: 800px !important;
    }

    .searchbar {
        width: auto;
    }
}