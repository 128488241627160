.inputfield {
    width: 400px !important;
    border-radius: 80px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.inputfieldtextarea {
    width: 400px;
    border-radius: 10px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.error {
    margin-bottom: 0 !important;
}

.inputfieldtextarea {
    width: 400px;
    border-radius: 10px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
}

.modelcontent {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.cancelbutton,
.cancelbutton:hover,
.cancelbutton:focus,
.cancelbutton:active {
    border: 1px solid #DDDCDC;
    border-radius: 30px;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #000000;
    width: 120px !important;
}

.submitbutton,
.submitbutton:active,
.submitbutton:focus,
.submitbutton:hover {
    border-radius: 30px;
    font-size: 14px;
    background-color: #EEA534;
    color: #FFFFFF;
    width: 120px !important;
    border: none !important;
}

.modelcontent {
    font-size: 16px;
    font-weight: 500;
}

.formsection {
    width: 50%;
}

.mainlogin {
    min-height: 100vh;
    overflow: hidden;
    background-color: #FFFFFF;
  }
  
  .loginformsection {
    padding: 20px;
  }
  
  .loginform {
    width: '30%';
    height: '100vh';
    border-right: '1px solid #545454';
  }
  
  .logoImage {
    height: 80px;
    width: auto;
  }
  
  .heading {
    font-size: 30px;
  }
  
  .backcolor {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100vh;
    background: radial-gradient(circle, #EAF2F2 20%, #EED378 80%);
    width: 100vw;
    background-color: #FFFFFF;
    position: relative;
  }
  
  .eye {
    color: #545454;
  }
  
  .errormsg {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  
  .loginimg {
    max-width: 80%;
    height: 80%;
  }
  
  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25), 0 0 0 1000px rgba(255, 255, 255, 0.5) inset, -10px 0 10px -8px rgba(0, 0, 0, 0.5) !important;
  }
  
  .form-control::placeholder {
    font-size: 14px;
  }

  .passwordfield{
    display: flex;
  }
  
  .inputbtn {
    padding-left: 15px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    background-color: #F2F2F2;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .eybtn,
  .eybtn:hover {
    padding-left: 10px;
    border: none;
    color: #545454;
    background-color: #F2F2F2;
    padding-right: 10px;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
  }
  
  .loginButton:focus,
  .loginButton,
  .loginButton:hover {
    width: 100%;
    color: #FFFFFF;
    border-radius: 80px;
    background-color: #EED378;
    border: none !important;
    margin-top: 2rem;
    width: 300px;
  }
  
  .content {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  
  .label {
    font-size: 16px;
    font-weight: 500;
  }
  
  .inputfield,
  .inputfield:hover,
  .inputfield:focus {
    background-color: #F2F2F2;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none !important;
    border-radius: 80px;
    font-size: 14px;
  }
  
  .reset:hover {
    color: #0d6efd !important;
  }
  
  @media screen and (max-width: 1440px) {
    .content {
      font-size: 20px;
    }
  
    .heading {
      font-size: 22px;
    }
  
    .logo {
      height: 150px;
      width: 150px;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .content {
      font-size: 18px;
    }
  
    .heading {
      font-size: 20px;
    }
  
    .logo {
      height: 120px;
      width: 120px;
    }
  }
  
  @media screen and (max-width: 996px) {
  
    .backcolor {
      width: 60%;
      display: none;
    }
  }
  
  @media screen and (max-width: 768px) {
    .loginform {
      width: 100%;
      /* Adjust the width for smaller screens */
    }
  
  }
  
  @media screen and (max-width: 480px) {
    .loginform {
      width: 100%;
      /* Adjust the width for smaller screens */
      padding-left: 0;
      padding-right: 0;
    }
  
    .logincontent {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .backcolor {
      width: 100%;
      /* Fill the entire width on smaller screens */
    }
  }
@media only screen and (max-width: 1200px) {

    .inputfield,
    .inputfieldtextarea {
        width: 100% !important;
    }
}

@media only screen and (max-width: 996px) {
    .formsection {
        width: 100%;
    }
}