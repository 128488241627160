/* loading.module.css */

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Position the loading spinner container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1111;
  background: rgba(229, 234, 235, 0.8); /* Semi-transparent background */
}

.loadingSpinner {
  color: #EEA534; /* Set the color you want */
}

/* Add styles to hide body overflow */
body.loading {
  overflow: hidden;
}
